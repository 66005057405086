import React, { useState } from 'react';
import { 
  makeStyles, 
  unstable_createMuiStrictModeTheme as createMuiTheme, // https://github.com/mui-org/material-ui/issues/13394
  ThemeProvider 
} from '@material-ui/core/styles';
import { Snackbar, Paper } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  blue,
  indigo,
  pink,
  deepOrange,
} from "@material-ui/core/colors";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { CounterTaler } from './CounterTaler';
import { AppBarTaler }  from './AppBarTaler';

// Background: playing card (similar to https://www.google.com/imgres?imgurl=https%3A%2F%2Fak.picdn.net%2Fshutterstock%2Fvideos%2F1014372209%2Fthumb%2F1.jpg&imgrefurl=https%3A%2F%2Fwww.shutterstock.com%2Fvideo%2Fclip-1014372209-playing-cards-background-ace-hearts-casino-abstract&tbnid=4p5NoyJr8H98KM&vet=12ahUKEwiXrqPojZvqAhWVk6QKHdLAATwQMygMegUIARDBAQ..i&docid=k4-ODWzUeDoIbM&w=852&h=480&q=background%20playing%20cards&ved=2ahUKEwiXrqPojZvqAhWVk6QKHdLAATwQMygMegUIARDBAQ)
import Image from './background.jpg'; // Import using relative path

const useStyles = makeStyles((theme) => ({
  outerApp: {
    display: "flex",
    flexFlow: "column",
    height: '100vh'
  },
  appContainer: {
    // background
    backgroundImage: `url(${Image})`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    // center and full screen
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: "1 1 auto"
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: '85vw',
  }
}));

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [ darkState, setDarkState ] = useState(prefersDarkMode);
  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      type: darkState ? "dark" : "light",
      primary: {
        main: darkState ? indigo[400] : blue[500],
      },
      secondary: {
        main: darkState ? deepOrange[600] : pink[500],
      }
    }
  });

  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  const [snackbarOpen,  setSnackbarOpen]  = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const [alertMessage,  setAlertMessage]  = React.useState('Initial Message!');
    
  const createSnackbar = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setSnackbarOpen(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.outerApp}>
        <AppBarTaler 
          darkState={darkState}
          handleThemeChange={handleThemeChange}
        />
        <div className={classes.appContainer}>
          <Paper elevation={3} className={classes.paper}>
            <CounterTaler createSnackbar={(severity, message) => createSnackbar(severity, message)} />
          </Paper>
        </div>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
        <MuiAlert elevation={6} variant="filled" severity={alertSeverity} onClose={() => setSnackbarOpen(false)}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}