import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import { 
  TextField, 
  Button,
  ListItemText,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Grid,
  Typography,
  Switch,
} from '@material-ui/core';

export class GameSetup extends Component {

  state = {
    newName: '',
    nameList: [],
    deckSize: 32,
  }

  useStyles() {
    return makeStyles((theme) => ({
      formRoot: {
        '& > *': {
          margin: theme.spacing(1),
          width: '25ch',
        },
      },
      list: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
      button: {
        variant: 'contained', 
        color: 'primary' 
      },
    }));
  }

  addName(newName){
    let nameList = this.state.nameList;
    if (!newName || nameList.find(name => { return name === newName})) {
      this.props.createSnackbar('error', 'Name is empty or already exists');
      return;
    }
    nameList.push(newName);
    this.setState({
      newName: '', 
      nameList: nameList
    });
  }

  removeName(index) {
    this.state.nameList.splice(index, 1);
    this.setState({ nameList: this.state.nameList });
  }

  render() {
    const { newName, nameList } = this.state
    const classes = this.useStyles();
    return (
      <>
        <Typography variant="h2" gutterBottom align="center">
          Playerlist
        </Typography>
        <Box mb={2} mt={-3} mx={2}>
          <List dense className={classes.list} aria-label="secondary mailbox folders">
            {nameList.map((name, index) => 
              <ListItem key={index}>
                <ListItemText primary={(index + 1) + '. ' + name} /> 
                <ListItemSecondaryAction>
                  <IconButton size="small" edge="end" aria-label="comments" onClick={() => this.removeName(index)}>
                    <RemoveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
          <TextField 
            fullWidth
            variant="outlined"
            label="Player name" 
            value = {newName}
            onChange={e => this.setState({ newName: e.target.value })}
            onKeyDown={e => { if (e.key === 'Enter') this.addName(e.target.value)}}
          />
        </Box>
        <Box mx={2}>
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
              <Typography>Decksize:</Typography>
            </Grid>
            <Grid item>
              <Typography display="inline">32</Typography>
              <Switch checked={this.state.deckSize !== 32} onChange={e => { this.setState({ deckSize: e.target.checked ? 52 : 32 }) }} color="default" /> 
              <Typography display="inline">52</Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {this.addName(newName)}} 
              >
              Add Player&nbsp;
            </Button>
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={() => {this.props.finishSetup(this.state.nameList, this.state.deckSize)}} 
              >
              Start game
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}