import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Checkbox,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  row: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  }
}));

export function PlayerList(props) {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small">
        <colgroup>
          <col width="10%" />
          <col width="30%" />
          <col width="10%" />
          <col width="25%" />
          <col width="25%" />
        </colgroup>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell className={classes.cell} align="right" >Rank</TableCell>
            <TableCell className={classes.cell} align="center">Name</TableCell>
            <TableCell className={classes.cell} align="right" >Points</TableCell>
            <TableCell className={classes.cell} align="center">tricks made</TableCell>
            <TableCell className={classes.cell} align="center">correct call </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.playerList.map(player => 
            <TableRow className={classes.row} key={player.name}>
              <TableCell className={classes.cell} align="right">{player.rank}</TableCell>
              <TableCell className={classes.cell} align="center">{player.name}</TableCell>
              <TableCell className={classes.cell} align="right">{player.points}</TableCell>
              <TableCell align="center">
                <TextField 
                  id="standard-basic" 
                  label="number" 
                  type="number"
                  value = {player.tricksMade}
                  onChange={e => props.setPlayerTricksMade(player, parseInt(e.target.value))}
                />
              </TableCell>
              <TableCell className={classes.cell} align="center">
                <Checkbox
                checked={player.rightCall}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onClick={e => { props.togglePlayerRightCall(player) }}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}