import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

export function AppBarTaler(props) {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Countertaler
        </Typography>
        <Switch checked={props.darkState} onChange={() => props.handleThemeChange()} />
      </Toolbar>
    </AppBar>
  )
}