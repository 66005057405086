import React, { Component } from 'react';
import {
  ButtonGroup,
  Grid,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { cloneDeep } from "lodash"
import  {
  ArrowLeft,
  ArrowRight,
  Replay,
  Home
} from '@material-ui/icons';

import { GameSetup }  from './GameSetup';
import { PlayerList }  from './PlayerList';

export class CounterTaler extends Component {

  state = {
    deckSize: 32,
    inGame: false,
    playerList: [],
    cardsToGive: 1,
    round: 1,
  }

  // for GameSetup
  startGame(nameList, deckSize) {
    if (!nameList || nameList.length < 2) {
      this.props.createSnackbar('error', 'At least two player!');
      return;
    }

    let playerList = nameList.map((name, index) => ({rank: 1, name: name, points: 0, tricksMade: 0, rightCall: true, position: index }));
    this.setState({
      deckSize: deckSize,
      inGame: true,
      playerList: playerList,
      initialPlayerList: JSON.parse(JSON.stringify(playerList)),
      round: 1,
      cardsToGive: 1,
      history: [],
    });
  }

  // for PlayerList
  togglePlayerRightCall(player) {
    player.rightCall = !player.rightCall;
    this.setState(this.state);
  }

  setPlayerTricksMade(player, tricksMade) {
    player.tricksMade = tricksMade;
    this.setState(this.state);
  }

  // for this component
  endRound() {
    // compute history
    const snapshot = cloneDeep(this.state.playerList);
    const history = this.state.history.slice();
    history.push(snapshot)

    // compute points
    this.state.playerList.forEach(player => {
      player.points += player.tricksMade + (player.rightCall ? 10 : 0);
      player.rightCall = true;
      player.tricksMade = 0;
    });
    // determine new ranks
    let lastPoints = 0;
    let lastRank = 0;
    this.state.playerList.sort((a, b) =>  (a.points < b.points) ? 1 : -1).forEach((player, index) => {
      player.rank = player.points === lastPoints ? lastRank : index + 1;
      lastPoints = player.points;
      lastRank = player.rank;
    });
    // determine how many cards to give next round ( 1 -++-> max -> *-1 -++> 0 -> game over ) value has to be interpreted as abs: |value|
    const cardsGiven = this.state.cardsToGive;
    this.setState(prevState => ({
      round: prevState.round+1,
      cardsToGive: ((cardsGiven + 1) * this.state.playerList.length) > this.state.deckSize ? cardsGiven * -1 : cardsGiven + 1,
      history: history
    }));
  }

  goBack() {
    if (this.state.history.length === 0) {
      return;
    }
    const lastState = this.state.history.pop();
    this.setState(prevState => ({
      round: prevState.round-1,
      playerList: lastState,
      cardsToGive: ((this.state.cardsToGive - 1) * -this.state.playerList.length) > this.state.deckSize ? this.state.cardsToGive * -1 : this.state.cardsToGive - 1,
    }))
  }

  restartGame() {
    this.setState({
      playerList: JSON.parse(JSON.stringify(this.state.initialPlayerList)),
      round: 1,
      cardsToGive: 1,
      history: [],
    });
  }

  resetGame() {
    this.setState({ inGame: false });
  }

  // returns an array of player who won
  determineWinner() {
    let highestScore = 0;
    this.state.playerList.forEach(player => { 
      if (player.points > highestScore) 
        highestScore = player.points;
    });
    return this.state.playerList.filter(player => player.points === highestScore);
  }

  render() {
    const { inGame, playerList, round } = this.state;
    const cardsToGive = Math.abs(this.state.cardsToGive);
    const dealer = playerList.filter(player => player.position === (round-1) % playerList.length )[0];
    
    return (!inGame ?
      <GameSetup
        createSnackbar={(severity, message) => this.props.createSnackbar(severity, message)} 
        finishSetup={(nameList, deckSize) => this.startGame(nameList, deckSize)} 
      />
      :
      <div>
        <PlayerList 
          playerList={playerList}
          setPlayerTricksMade={(player, tricks) => this.setPlayerTricksMade(player, tricks)}
          togglePlayerRightCall={(player) => this.togglePlayerRightCall(player)}
        />
        {this.state.cardsToGive !== 0 &&
          <Box mt={3}>
            <Grid container justify="center">
              <Typography>{`Round ${round}: ${dealer.name} gives ${cardsToGive} Card${(cardsToGive === 1) ? '' : 's'} `}</Typography>
            </Grid>
          </Box>
        }
        <Box mt={2}>
          <Grid container justify="space-between" alignItems="center">
            {this.state.cardsToGive !== 0 ?
              <Grid item>
                <ButtonGroup disableElevation variant="contained" color="primary">
                  <IconButton onClick={() => {this.goBack()}} disabled={this.state.history.length === 0}>
                    <ArrowLeft/>
                  </IconButton>
                  <IconButton onClick={() => {this.endRound()}}>
                    <ArrowRight/>
                  </IconButton>
                </ButtonGroup>
              </Grid>
              :
              <Grid item xs container alignItems="center">
                <Typography align="center" variant="subtitle1" display="block">
                  {'Winner: ' + this.state.playerList.filter(player => player.rank === 1).map(player => player.name).join(', ')}  
                </Typography>
              </Grid>
            }
            <Grid item>
              <ButtonGroup disableElevation variant="contained" color="secondary">
                <IconButton onClick={() => {this.restartGame()}}>
                  <Replay/>
                </IconButton>
                <IconButton onClick={() => {this.resetGame()}}>
                  <Home/>
                </IconButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Box>
      </div>
    ) 
  }
}